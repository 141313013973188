import Vue from 'vue';
import VueRouter from "vue-router";
import App from './App.vue'
import WeirPage from "@/components/WeirPage/WeirPage";
import {HurleyWeir} from "@/js/Hurley";
import {BoultersWeir} from "@/js/Boulters";

Vue.config.productionTip = false

const hurley = new HurleyWeir()
const boulters = new BoultersWeir()

const routes = [
    {path: '/weir/hurley', component: WeirPage, props: {weir: hurley}},
    {path: '/weir/boulters', component: WeirPage, props: {weir: boulters}},
    {path: '/', redirect: '/weir/hurley'}
]

Vue.use(VueRouter)

let router = new VueRouter({
    routes, // short for `routes: routes`
})

new Vue({
    render: h => h(App),
    router
}).$mount('#app')

