<template>
<div>
  <p>This page is based on the algorithm determined by the fine folks at
    <a href="https://tvfreestylers.co.uk/index.php/thames-weir-guides/hurley-weir/flow-rates-and-gate-prediction-at-hurley/">
      Thames Valley Freestylers
    </a>
  </p>
  <p>This uses Environment Agency flood and river level data from the real-time data API (Beta)</p>
</div>
</template>

<script>
export default {
name: "acknowledgements"
}
</script>

<style scoped>

</style>