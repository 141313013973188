import {Weir} from "@/js/Weir";

class HurleyWeir extends Weir {
    gauge_url = 'https://environment.data.gov.uk/flood-monitoring/id/measures/2604TH-flow--i-15_min-m3_s/readings?_sorted&_limit=200'
    label = 'm³/s Flow at Maidenhead'
    gate_prediction = true
    running_prediction = false


    getGatesPrediction() {
        let hurley = 0
        if (this.flowrate > 100) {
            hurley = 4
        } else if (this.flowrate > 65) {
            hurley = 3
        } else if (this.flowrate > 45) {
            hurley = 2
        } else if (this.flowrate > 25) {
            hurley = 1
        }
        return hurley
    }

    getName() {
        return "Hurley"
    }

}

export {HurleyWeir}