<script>
import {Line, mixins} from 'vue-chartjs'
import chartjsPluginAnnotation from "chartjs-plugin-annotation"


export default {
  name: "WeirChart",
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: "time",
            time: {
              tooltipFormat: 'lll',
              displayFormats: {
                minute: 'DD/MM hh:mm',
                hour: 'DD/MM hh:mm'
              },
              distribution: 'linear'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        annotation: {
          events: ["click"],
          annotations: [
            {
              drawTime: "afterDatasetsDraw",
              id: "hline",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: 90,
              borderColor: "black",
              borderWidth: 5,
            },
          ]
        }
      },
    }
  },
  mounted() {
    this.addPlugin(chartjsPluginAnnotation)
    this.renderChart(this.chartData, this.options)
  }
}
</script>
