<template>
  <div id="app">
    <NavBar></NavBar>
    <router-view :key="$route.path"></router-view>
    <acknowledgements></acknowledgements>
  </div>
</template>

<script>
import NavBar from "@/components/Nav/NavBar";
import acknowledgements from "@/components/acknowledgements/Acknowledgements";

export default {
  name: 'App',
  components: {
    NavBar,
    acknowledgements
  }
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
