<template>
  <div id="gateprediction">
    <p>I think there will be</p>
    <h2>{{ gates }}</h2>
    <p>gates open at {{ weirName }} today</p>
  </div>
</template>

<script>
export default {
  name: "gate-prediction",
  props: ['weirName', 'gates']
}
</script>

<style scoped>

</style>