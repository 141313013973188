<template>
  <div class="navbar">
    <ul class="navlinks">
      <NavBarItem v-for="menuitem in menuItems" :item="menuitem" :key="menuitem.id"></NavBarItem>
    </ul>
  </div>
</template>

<script>
import NavBarItem from "@/components/Nav/NavBarItem";

export default {
  name: 'NavBar',
  components: {NavBarItem},
  data: function () {
    return {
      menuItems: [
        {id: 0, link:'/weir/hurley', text:'Hurley Weir'},
        {id: 1, link:'/weir/boulters', text:'Boulters Weir'}
      ]
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
}
</style>
