import {Weir} from "@/js/Weir";

class BoultersWeir extends Weir {
    gauge_url = 'https://environment.data.gov.uk/flood-monitoring/id/measures/2603TH-flow--i-15_min-m3_s/readings?_sorted&_limit=200'
    label = 'Jubilee River m³/s Flow at Taplow'
    gate_prediction = false
    running_prediction = true

    getName() {
        return "Big Boulters"
    }

    getRunningPrediction(flowRate) {
        if (flowRate > 35) {
            return 'YES'
        } else {
            return 'NO'
        }
    }
}

export {BoultersWeir}