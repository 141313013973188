<template>
  <li><router-link v-bind:to="item.link">{{item.text}}</router-link></li>
</template>

<script>
export default {
  name: "NavBarItem",
  props: ['item']
}
</script>

<style scoped>
li {
  display: inline;
}

a {
  color: #4b42f5;
  font-size: large;
  border-color: #4b42f5;
  border-style: outset;
  border-radius: 10px;
  text-decoration: none;
  padding: 10px;
  margin: 10px;
}
</style>