<template>
  <div>
    <h1>Weir Levels for {{ weir.getName() }}</h1>
    <GatePrediction v-if="loaded && weir.gate_prediction" :weir-name="weir.getName()"
                    :gates="weir.getGatesPrediction()">
    </GatePrediction>
    <RunningPrediction v-if="loaded && weir.running_prediction" :weir-name="weir.getName()"
                       :result="weir.getRunningPrediction(this.flowrate)"></RunningPrediction>
    <WeirChartComponent v-if="loaded && weir" :weir="weir"></WeirChartComponent>
  </div>
</template>

<script>
import GatePrediction from "@/components/WeirPage/GatePrediction"
import RunningPrediction from "@/components/WeirPage/RunningPrediction";
import WeirChartComponent from "@/components/WeirPage/WeirChartComponent";

export default {
  name: 'WeirPage',
  props: ['weir'],
  components: {WeirChartComponent, GatePrediction, RunningPrediction},
  data() {
    return {
      loaded: false,
      weirData: this.fetchWeirData()
    }
  },
  methods: {
    fetchWeirData() {
      this.weir.getFlowRate().then(flowrate => {
        this.loaded = true
        this.flowrate = flowrate
      })
    }
  },
  mounted() {
    this.fetchWeirData()
  }
}
</script>

<style scoped>

</style>