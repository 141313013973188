import { render, staticRenderFns } from "./WeirPage.vue?vue&type=template&id=e00f6d72&scoped=true&"
import script from "./WeirPage.vue?vue&type=script&lang=js&"
export * from "./WeirPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e00f6d72",
  null
  
)

export default component.exports