<template>
  <div id="runningprediction">
    <p>Do I think that {{ weirName }} will be running today?</p>
    <h2>{{ result }}</h2>
  </div>
</template>

<script>
export default {
  name: "gate-prediction",
  props: {
    weirName: String,
    result: String
  },
}
</script>

<style scoped>

</style>