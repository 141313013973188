import axios from 'axios'

class Weir {
    flowrate = 0
    label = "Gauge Name"
    gauge_url = ""
    graphLabels = []
    levels = {}

    getLevels() {
        return this.levels
    }

    getFlowRate() {
        let weir = this
        return new Promise(function (resolve) {
            axios.get(weir.gauge_url)
                .then(response => {
                    weir.graphLabels = []
                    let readings = []
                    for (let reading of response.data.items) {
                        let datetime = new Date(reading.dateTime)
                        readings.push({x: datetime, y: reading.value})
                    }
                    weir.graphLabels.reverse()
                    readings.reverse()
                    weir.flowrate = readings[readings.length - 1].y
                    weir.levels = {
                        datasets: [
                            {
                                label: weir.label,
                                backgroundColor: '#4b42f5',
                                borderColor: '#4b42f5',
                                data: readings,
                                fill: false,
                                pointStyle: 'cross'
                            },
                        ]
                    }
                    resolve(weir.flowrate)
                })
        })
    }


    constructor() {
    }

    getName() {
        return "Unnamed Weir"
    }
}

export {Weir}
