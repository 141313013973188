<template>
  <WeirChart
      :chart-data="chartData"/>
</template>

<script>
import WeirChart from "@/components/WeirPage/WeirChart";
import {Weir} from "@/js/Weir"

export default {
  name: "WeirChartComponent",
  props: {
    weir: Weir,
    options: {}
  },
  components: {WeirChart},
  data: function () {
    return {
      chartData: this.weir.getLevels()
    }
  }
}
</script>

<style scoped>

</style>